<template>
  <div class="exDetail_container">
    <div class="mask" v-if="notice"></div>
    <el-row class="exDetail_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <!-- <el-row class="detail_tip">
      <h1>温馨提示：点击查看体检项目详情</h1>
    </el-row> -->

    <div class="exDetail_main">
      <el-table :data="tableData" v-loading="loading" border="" height="100%" @row-click="on_select" style="width: 100%;font-size: 50px;">
        <el-table-column label="项目名称" prop="itemName"></el-table-column>
        <el-table-column label="项目结果" prop="result"></el-table-column>
        <el-table-column label="单位" prop="unit"></el-table-column>
        <el-table-column label="参考下限" prop="lowerValue"></el-table-column>
        <el-table-column label="参考上限" prop="upperValue"></el-table-column>
      </el-table>
    </div>
    <el-button type="primary" v-if="!notice" class="home" @click.native="home">首页</el-button>
    <el-button type="primary" @click.native="back" class="cheakBack">返回</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from '@/baseAPI'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '体检报告详情',
      notice: false,
      tableData: [],
      query: {},
      loading: false
    }
  },
  created() {
    this.query = this.$route.query
    this.orderNum = this.$route.query.orderNum
    this.peReportResultDetail()
  },
  methods: {
    async peReportResultDetail() {
      this.loading = true
      const { data: res } = await jsonPost('/pe/peReportResultDetail', {
        reportNo: this.query.reportNo,
        groupCode: this.query.groupCode
      })
      if (res.code === 0) {
        this.loading = false
        this.tableData = res.data.itemList
      } else {
        this.loading = false
        this.$message({
          message: data[0].msg,
          type: 'error'
        })
      }
    },
    on_select(val) {
      //点击行选中复选框
      const params = {
        reportNo: this.query.reportNo,
        groupCode: val.val
      }
      this.$router.push({
        path: '/peExaminDetail',
        query: params
      })
    },
    ok() {
      this.notice = false
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    }
  }
}
</script>
<style>
.exDetail_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.exDetail_container .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.1;
  z-index: 1;
}

.exDetail_container .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;
  width: 70%;
  height: 60%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.exDetail_container .popup h1 {
  text-align: center;
  letter-spacing: 10px;
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.exDetail_container .popup p {
  margin-bottom: 20px;
  color: red;
  font-size: 28px;
  line-height: 40px;
}

.exDetail_container .popup .el-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 28px !important;
}

.exDetail_main {
  width: 90%;
  height: 40%;
  margin: 30% auto;
}

.exDetail_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.exDetail_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
}

.exDetail_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.detail_tip h1 {
  margin-top: 20%;
  font-weight: 400;
  color: red;
  text-align: center;
  font-size: 34px;
}

.exDetail_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.suggest {
  margin-top: 10px;
  padding: 20px;
  color: red;
}

.exDetail_info .left {
  float: left;
  width: 50%;
}

.exDetail_info .right {
  float: right;
  width: 50%;
}

.exDetail_info p {
  padding-left: 30%;
  font-size: 26px;
  color: #458ce9;
  margin-bottom: 20px;
}

.exDetail_container .home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}

.exDetail_container .pay {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  font-size: 26px;
}

.cheakBack {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px !important;
}
</style>
